import React from 'react'
import styled from 'styled-components'
import { mixins } from '../../styling/mixins'

export type ButtonProps = {
  $fullWidth?: boolean
  as?: React.ElementType | keyof JSX.IntrinsicElements
  size?: 'small' | undefined
  variant?: 'primary' | 'secondary' | 'negative'
}

const ButtonBase = styled.button`
  ${mixins.button.buttonBase}
`

const Button = styled.button<any>`
  box-sizing: border-box;
  margin-top: 32px;
  margin-bottom: 48px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 13px 16px;
  gap: 4px;

  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  display: flex;
  align-items: center;
  border: 2px solid #000000;
  border-radius: 8px;
  text-decoration: none;

  background: #000000;
  color: #fff;
  cursor: pointer;

  &:disabled {
    background: #444444;
    color: #ddd;
    cursor: not-allowed;
    border: 2px solid #444444;
  }
`

export default Button
export { ButtonBase }
