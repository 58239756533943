import React from 'react'
import styled from 'styled-components'

export type ButtonProps = {
  $fullWidth?: boolean
  as?: React.ElementType | keyof JSX.IntrinsicElements
  size?: 'small' | undefined
  variant?: 'primary' | 'secondary' | 'negative'
}

const DeleteButton = styled.button<any>`
  box-sizing: border-box;
  margin-top: 32px;
  margin-bottom: 48px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 13px 16px;
  gap: 4px;
  color: #fff;
  cursor: pointer;

  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  background: #b40000;
  border: 2px solid #b40000;
  border-radius: 8px;
`

export default DeleteButton
