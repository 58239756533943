import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Locale } from 'traficom-registry-shared'
import { theme } from '../../styling/theme'
import { ButtonBase } from '../button/button'
import Dropdown from '../dropdown/dropdown'
import { ReactComponent as LanguageIcon } from '../../images/language_icon.svg'

const LanguageButton = styled(ButtonBase)<any>`
  margin: ${p => p.theme.spacing(0.2)};
  font-weight: ${props => (props.isActive ? '700' : 'normal')};
  color: ${props => props.theme.palette.text.dark};
  font-size: 1rem;
  white-space: nowrap;
  text-align: left;

  &:active,
  &:focus {
    outline-color: ${p => p.theme.palette.black};
  }
`

const StyledLanguageIcon = styled(LanguageIcon)<any>``

const StyledButtonBase = styled(ButtonBase)<any>`
  height: 100%;
  padding: ${p => p.theme.spacing(1)};
  white-space: nowrap;
  color: ${props => props.theme.palette.text.dark};

  & > ${StyledLanguageIcon} {
    height: 16px;
    width: 16px;
    margin-right: ${p => p.theme.spacing(0.5)};
  }
`

const LanguageToggle: React.FC = ({ children, ...props }) => (
  <StyledButtonBase {...props}>
    <StyledLanguageIcon role="presentation" />
    {children}
  </StyledButtonBase>
)

const languageTexts: Record<Locale.UserLanguage, string> = {
  fi: 'Suomeksi',
  sv: 'På Svenska',
  en: 'In English',
}

const StyledDropdown = styled(Dropdown)`
  display: none;

  ${p => p.theme.mediaquery('sm')} {
    display: inline-block;
  }
`

const LanguageSelector: React.FunctionComponent = () => {
  const { t, i18n } = useTranslation()
  const changeLanguage = (language: Locale.UserLanguage): void => {
    i18n.changeLanguage(language)
  }
  const selectableLanguages = Locale.languageOptions.filter(option => option !== i18n.language)
  return (
    <StyledDropdown
      id="language-selector"
      toggleText={languageTexts[i18n.language as Locale.UserLanguage]}
      toggleAs={LanguageToggle}
      label={t('language_selector')}
      chevron
      outlineColor={theme.palette.black}
    >
      {selectableLanguages.map(language => (
        <LanguageButton isActive={i18n.language === language} key={language} onClick={() => changeLanguage(language)}>
          {languageTexts[language]}
        </LanguageButton>
      ))}
    </StyledDropdown>
  )
}

export default LanguageSelector
