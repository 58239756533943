import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Page } from '../layout-user-mgmt'
import { useMsalAuthentication } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'
import { api } from '../../services/api'
import { Comp } from '../../utils/component'
import Loader from '../../ui-user-mgmt/loader/loader'
import {
  Form,
  //  Section,
  Submit,
  SubmitButton,
  TextField,
  useFormErrorHandler,
} from '../../ui-user-mgmt/form'
import { getRedirectRequest } from './MsalInstance'
import { Button } from '../../ui-user-mgmt'
import { TokenClaims } from '@azure/msal-common'
import { ErrorState } from '../error/error-page'
import { validators } from 'traficom-registry-shared'
import styled from 'styled-components'
import { FormValues } from '../../utils/types'
import { User } from '@microsoft/microsoft-graph-types'

type MailAddress = { mail: string }

/* eslint-disable */

export const AdminUsers: Comp = () => {
  const { t } = useTranslation()
  const handleError = useFormErrorHandler<MailAddress>()
  const [roles, setRoles] = useState<string[]>([])
  const [idTokenClaims, setIdTokenClaims] = useState<TokenClaims>()
  const [accessToken, setAccessToken] = useState<string>('')
  const [users, setUsers] = useState<User[]>([])
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)

  const tokenRequest = getRedirectRequest('/#/admin/users')
  const { result, error } = useMsalAuthentication(InteractionType.Redirect, tokenRequest)

  useEffect(() => {
    const fetchData = async () => {
      if (result) {
        const claims = result.idTokenClaims as TokenClaims
        setIdTokenClaims(claims)
        setRoles(claims.roles ?? [])
        setAccessToken(result.accessToken)
        setLoadingComplete(true)
      }
    }

    fetchData().catch(console.error)
  }, [error, result])

  if (error) {
    return <ErrorState error="generic" />
  }

  return (
    <PageContainer>
      <FormAndTitleContainer>
        <Page.Content title={t('user_management:ans_admin')}>
          {!loadingComplete && <Loader text={t('common:loading')} />}
          {loadingComplete && roles.some(r => r === 'ans_admin') ? (
            <>
              <h1>{t('user_management:users')}</h1>
              <Users items={users} token={accessToken} setUsers={setUsers} />
            </>
          ) : (
            loadingComplete && <p>{t('user_management:no_access')}</p>
          )}
        </Page.Content>
      </FormAndTitleContainer>
    </PageContainer>
  )
}

const List = styled.ul<any>`
  list-style: none;
  padding: 0px 20px;
  background-color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: 3px solid ${'#9b8dab'};
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.16);
`

const ListItem = styled.li<any>`
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  border-top: 1px solid ${'#e0dddd'};
  :first-of-type {
    border-top: none;
  }
`

const ErrorMessage = styled.div`
  color: red;
`

const PageContainer = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: center;
  border-top: 1px solid #000;
`

const FormAndTitleContainer = styled.div`
  width: 50%;
  padding-bottom: 48px;
`

const FormContainer = styled.div`
  width: 100%;
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
`

const Xicon = styled.span`
  padding 0px 14px;
  font-size: 18px;
  font-weight: 500;
`

const UserDataContainer = styled.div`
  padding: 16px 4px 0;
`

const UserDataItem = styled.div`
  padding: 4px 0;
`

const Users: Comp<{ items: User[]; token: string; setUsers: Function }> = props => {
  const { t } = useTranslation()
  const [noSearchResults, setNoSearchResults] = useState(false)

  const userSearchDefaults = (): FormValues<{ email: string }> => ({
    email: '',
  })

  const submit: Submit<{ email: string }> = async (values, helpers) => {
    const result = await api.getUsersByEmail(values, { accessToken: props.token })
    if (result.success) {
      if (result.data.length > 0) {
        props.setUsers(result.data)
        setNoSearchResults(false)
      } else {
        props.setUsers([])
        setNoSearchResults(true)
      }
    } else {
      console.error(result)
    }
    helpers.setSubmitting(false)
    helpers.resetForm()
  }
  return (
    <>
      <Form<{ email: string }> initialValues={userSearchDefaults()} onSubmit={submit} validate={validators.getUsersByEmail}>
        <TextField label={t('user_management:user_search_label')} name="email" disabled={false} />
        <SubmitButton labelKey="common:search" />
      </Form>
      {noSearchResults ? <ErrorMessage>{t('user_management:search_failed')}</ErrorMessage> : ''}
      {props.items.length > 0 && (
        <List>
          {props.items.map(item => (
            <ListItem key={item.id}>
              <UserDataContainer>
                <UserDataItem>{item.displayName}</UserDataItem>
                <UserDataItem>{item.mail}</UserDataItem>
                <UserDataItem>{item.mobilePhone}</UserDataItem>
              </UserDataContainer>
              <Button
                variant="primary"
                size="small"
                onClick={async () => {
                  if (window.confirm(t('user_management:confirm_delete_user'))) {
                    const result = await api.deleteUserById({ userId: item.id }, { accessToken: props.token })
                    if (result.success) {
                      window.location.reload()
                    } else {
                      console.error() // TODO
                    }
                  }
                }}
              >
                {t('user_management:delete_profile')}
              </Button>
            </ListItem>
          ))}
        </List>
      )}
    </>
  )
}
