import React from 'react'
import styled, { keyframes } from 'styled-components'

export type LoaderProps = {
  negative?: boolean
  size?: 'small'
  text?: string
}

const LoaderWrapper = styled.div<any>`
  position: relative;
  display: flex;
  justify-content: center;
  ${props =>
    props.size === 'small' && {
      width: 25,
      height: 19,
    }}
`

const LoaderInner = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
`

const spinningAnimation = keyframes`
  0% { transform: rotate(0deg) }
  to { transform: rotate(1turn) }
`

const Spinner = styled.div<LoaderProps>`
  border: ${p => p.theme.borders['l']} solid
    ${props => (props.negative ? props.theme.palette.white : props.theme.palette.primary[500])};
  border-top-color: transparent;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  animation: ${spinningAnimation} 2s cubic-bezier(0.45, 0.05, 0.55, 0.95) infinite;
  ${props =>
    props.size === 'small' && {
      borderWidth: '0.2rem',
      width: '1.5rem',
      height: '1.5rem',
    }};
`

const TextWrapper = styled.p`
  margin-top: ${p => p.theme.spacing(5)};
`

const Loader: React.FunctionComponent<LoaderProps> = ({ size, negative, text }) => {
  return (
    <LoaderWrapper size={size}>
      <LoaderInner>
        <Spinner size={size} negative={negative} />
      </LoaderInner>
      {text && <TextWrapper>{text}</TextWrapper>}
    </LoaderWrapper>
  )
}

export default Loader
