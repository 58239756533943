import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Comp } from '../../../utils/component'
import { FieldGroup } from '../layout'

export type RadioValue = {
  value: string
  label: string
}

export type RadioBooleanValue = {
  value: 'true' | 'false'
  label: string
}

export type RadioProps = {
  id?: string
  label?: string
  name: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  selected: string
  options: RadioValue[]
  error?: string
}

const RadioRoot = styled.div<any>`
  margin-top: ${props => props.theme.spacing(1)};
  margin-bottom: ${props => props.theme.spacing(1)};

  display: flex;
  flex-direction: column;

  ${p => p.theme.mediaquery('sm')} {
    flex-direction: row;
    > :not(:first-child) {
      margin-left: ${p => p.theme.spacing(2)};
    }
  }
`

const StyledInput = styled.input<any>`
  /* Keep the element for accessibility */
  position: absolute;
  z-index: -1;
  opacity: 0;

  &:focus + .radio-circle {
    outline: 1px solid transparent;
    box-shadow: 0 0 0 2px ${props => props.theme.palette.focus};
  }
`

const StyledError = styled.span<any>`
  color: ${props => props.theme.palette.error};
`

const StyledHelperContainer = styled.div<any>`
  display: block;
  margin-top: ${props => props.theme.spacing(0.5)};
  font-size: 1rem;
  line-height: 1rem;
  min-height: 1rem;
`

const RadioLabel = styled.span<any>``

const ButtonLabel = styled.label<any>`
  cursor: pointer;
  display: flex;
  align-items: center;
`

const ButtonShell = styled.span<any>`
  height: 30px;
  width: 30px;
  border: 2px solid ${p => (p.error ? p.theme.palette.error : p.theme.palette.grey[600])};
  border-radius: 100%;
  background-color: ${p => p.theme.palette.white};

  margin: ${p => p.theme.spacing(1)};

  flex-shrink: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`

const ButtonDot = styled.span<any>`
  height: 16px;
  width: 16px;
  border: 8px solid ${p => p.theme.palette.primary[500]};
  border-radius: 100%;
`

const Radio: Comp<RadioProps> = props => {
  const { label, name, options, selected, onChange, error } = props
  const { t } = useTranslation()
  return (
    <FieldGroup fieldset title={label}>
      <RadioRoot>
        {options.map(({ value, label }) => (
          <ButtonLabel key={`${name}-${value}`} htmlFor={`${name}-${value}`}>
            <StyledInput
              type="radio"
              id={`${name}-${value}`}
              name={name}
              value={value}
              checked={selected === value}
              onChange={onChange}
            />
            <ButtonShell error={error} className="radio-circle">
              {selected === value && <ButtonDot />}
            </ButtonShell>
            <RadioLabel>{label}</RadioLabel>
          </ButtonLabel>
        ))}
      </RadioRoot>
      {error && (
        <StyledHelperContainer>
          <StyledError>{t(error)}</StyledError>
        </StyledHelperContainer>
      )}
    </FieldGroup>
  )
}

export default Radio
export { Radio }
