import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Page } from '../layout-user-mgmt'
import { AuthenticatedTemplate, useMsalAuthentication } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'
import { api } from '../../services/api'
import { Comp } from '../../utils/component'
import { Form, Submit, SubmitButton, TextField, useFormErrorHandler } from '../../ui-user-mgmt/form'
import { getRedirectRequest } from './MsalInstance'
import { FormValues } from '../../utils/types'
import { UserMgmt as U } from 'traficom-registry-shared'
import { validators } from 'traficom-registry-shared'
import { Button } from '../../ui-user-mgmt'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { AdditionalInfo } from './org-admin'

const organizationDefaults = (): FormValues<U.Organization> => ({
  name: '',
  businessId: '',
  contactName: '',
  address: '',
  postalCode: '',
  municipality: '',
  phoneNumber: '',
  contactPhoneNumber: '',
  astraGovernmentOrganization: '',
})

const PageContainer = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: center;
  border-top: 1px solid #000;
`

const FormAndTitleContainer = styled.div`
  width: 50%;
  padding-bottom: 48px;
`

const FormContainer = styled.div`
  width: 100%;
`

export const CreateOrganization: Comp = () => {
  const { t } = useTranslation()
  const [showCreateForm, setShowCreateForm] = useState<boolean>(true)
  const [createOrgStatus, setCreateOrgStatus] = useState<U.CreateOrganizationStatus>()
  const handleError = useFormErrorHandler<U.Organization>()

  const tokenRequest = getRedirectRequest('/#/create-organization')
  const { acquireToken } = useMsalAuthentication(InteractionType.Redirect, tokenRequest)

  const submit: Submit<U.Organization> = async (values, helpers) => {
    const authResult = await acquireToken(InteractionType.Redirect, tokenRequest)
    const token = authResult ? authResult.accessToken : ''
    const result = await api.createOrganization({ ...values }, { accessToken: token })
    if (result.success) {
      setShowCreateForm(false)
      setCreateOrgStatus(result.data)
    } else {
      handleError(result.data as any, helpers)
    }
  }

  return (
    <PageContainer>
      <AuthenticatedTemplate>
        <FormAndTitleContainer>
          <Page.Content title={t('user_management:create_organization')}>
            {showCreateForm && (
              <FormContainer>
                <Form<U.Organization>
                  initialValues={organizationDefaults()}
                  onSubmit={submit}
                  validate={validators.createOrganization}
                >
                  <AdditionalInfo>{t('common:asterisk_mandatory')}</AdditionalInfo>

                  <TextField label={t('personal_data:name')} name="name" disabled={false} mandatory={true} />
                  <TextField label={t('personal_data:company_id')} name="businessId" disabled={false} mandatory={true} />
                  <TextField label={t('personal_data:telephone')} name="phoneNumber" disabled={false} mandatory={true} />
                  <TextField label={t('personal_data:address')} name="address" disabled={false} />
                  <TextField label={t('personal_data:postal_code')} name="postalCode" disabled={false} />
                  <TextField label={t('personal_data:municipality')} name="municipality" disabled={false} />
                  <TextField label="Yhteyshenkilön nimi" name="contactName" disabled={false} mandatory={true} />
                  <TextField
                    label="Yhteyshenkilön puhelinnumero"
                    name="contactPhoneNumber"
                    disabled={false}
                    mandatory={true}
                  />
                  <SubmitButton labelKey="user_management:create_organization" />
                </Form>
              </FormContainer>
            )}

            {!showCreateForm && createOrgStatus == 'created' && (
              <FormContainer>
                <p>{t('user_management:organization_created_success')}</p>
                <Button $fullWidth as={Link} to="organizations">
                  {t('user_management:organization_created_continue')}
                </Button>
              </FormContainer>
            )}

            {!showCreateForm && createOrgStatus == 'pendingApproval' && (
              <FormContainer>
                <p>{t('user_management:organization_created_postponed')}</p>
                <Button $fullWidth as={Link} to="organizations">
                  {t('user_management:organization_created_continue')}
                </Button>
              </FormContainer>
            )}
          </Page.Content>
        </FormAndTitleContainer>
      </AuthenticatedTemplate>
    </PageContainer>
  )
}
