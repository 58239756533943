import * as builders from './builders'
import { create } from './util'

export const operator = create(builders.operator)
export const operatorOrganization = create(builders.operatorOrganization)
export const operatorPerson = create(builders.operatorPerson)

export const operatorPilot = create(builders.operatorPilot)
export const operatorPilotOrganization = create(builders.operatorPilotOrganization)
export const operatorPilotPerson = create(builders.operatorPilotPerson)

export const independentPilot = create(builders.independentPilot)

export const updateOperator = create(builders.updateOperator)
export const updateOperatorOrganization = create(builders.updateOperatorOrganization)
export const updateOperatorPerson = create(builders.updateOperatorPerson)

export const updatePilot = create(builders.updatePilot)

export const extendOperator = create(builders.extendOperator)

export const startVerification = create(builders.startVerification)
export const verifyEmail = create(builders.verifyEmail)

export const updateProfile = create(builders.updateProfile)
export const addMobilePhone = create(builders.addMobilePhone)
export const createOrganization = create(builders.createOrganization)
export const createOrganizationOnBehalf = create(builders.createOrganizationOnBehalf)
export const updateOrganization = create(builders.updateOrganization)
export const joinOrganization = create(builders.joinOrganizationWithBusinessId)
export const getOrganizationsByNameOrBusinessId = create(builders.getOrganizationsByNameOrBusinessId)
export const getUsersByEmail = create(builders.getUsersByEmail)
export const userId = create(builders.userId)
export const userIdAndBusinessId = create(builders.userIdAndBusinessId)
export const workQueueItem = create(builders.workQueueItem)

export const saveOrgGroupPermission = create(builders.saveOrgGroupPermission)
export const deleteOrgGroupPermission = create(builders.businessId)

export const withBusinessId = create(builders.businessId)
