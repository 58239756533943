import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export type CheckboxProps = {
  id?: string
  label?: string
  name: string
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  error?: string
  checked: boolean
}

const CheckboxRoot = styled.div<any>`
  padding: 1em 0;
  ${p => p.theme.mediaquery('sm')} {
    padding: 1em 0 0;
  }

  > label {
    display: flex;
    align-items: center;

    cursor: pointer;
  }
`

const StyledLabel = styled.span<any>`
  margin-left: ${props => props.theme.spacing(2)};
  hyphens: auto;
`

const StyledInput = styled.input<any>`
  /* Keep the element for accessibility */
  position: absolute;
  z-index: -1;
  opacity: 0;
  &:focus + span {
    outline: 2px solid ${props => props.theme.palette.focus};
  }
`

const StyledError = styled.span<any>`
  color: ${props => props.theme.palette.error};
`

const StyledHelperContainer = styled.div<any>`
  display: block;
  margin-top: ${props => props.theme.spacing(0.5)};
  font-size: 1rem;
  line-height: 1rem;
  min-height: 1rem;
`

const Checkbox: React.FunctionComponent<CheckboxProps> = props => {
  const { handleChange, checked, label, name, error } = props
  const { t } = useTranslation()
  return (
    <CheckboxRoot>
      <label>
        <StyledInput type="checkbox" name={name} onChange={handleChange} checked={checked} />
        <Box error={!!error}>{checked && <TickMark />}</Box>
        <StyledLabel>{label}</StyledLabel>
      </label>
      <StyledHelperContainer>{error && <StyledError>{t(error)}</StyledError>}</StyledHelperContainer>
    </CheckboxRoot>
  )
}

export default Checkbox
export { Checkbox }

const Box = styled.span<any>`
  height: 30px;
  width: 30px;
  border: 2px solid ${p => (p.error ? p.theme.palette.error : p.theme.palette.grey[600])};
  background-color: ${p => p.theme.palette.white};
  display: flex;
  flex: 0 0 auto;
`

const TickMark = styled.span<any>`
  position: relative;
  width: 20px;
  height: 20px;
  top: 1px;

  ::before {
    position: absolute;
    top: 50%;
    height: 50%;
    width: 3px;
    background-color: ${p => p.theme.palette.primary[500]};
    content: '';
    transform: translateX(10px) rotate(-45deg);
    transform-origin: left bottom;
  }

  ::after {
    position: absolute;
    bottom: 0;
    height: 3px;
    width: 100%;
    background-color: ${p => p.theme.palette.primary[500]};
    content: '';
    transform: translateX(10px) rotate(-45deg);
    transform-origin: left bottom;
  }
`
