import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

export type TextInputProps = {
  id: string
  label?: string
  name: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
  value: string
  error?: string
  fullWidth?: boolean
  disabled?: boolean
}

const TextInputRoot = styled.div<any>`
  position: relative;
  margin-top: ${props => props.theme.spacing(1)};
  margin-bottom: ${props => props.theme.spacing(1)};

  > input:focus + label {
    transform: translate(${props => props.theme.spacing(2)}, 7px);
    font-size: 13px;
  }

  ${({ isDirty }) => isDirty && labelFocusMixin}
`

const labelFocusMixin = css`
  > label {
    transform: translate(${props => props.theme.spacing(2)}, 7px);
    font-size: 13px;
  }
`

const StyledInput = styled.input<any>`
  font-size: 1rem;
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  height: 3.5rem;
  padding: 1.1rem ${props => props.theme.spacing(2)} 0;
  background-color: ${props => props.theme.palette.white};
  border: none;
  border-bottom: 2px solid ${props => props.theme.palette.grey[600]};
  transition: border-bottom 100ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 100ms cubic-bezier(0.2, 0, 0.38, 0.9);
  outline: 2px solid ${props => (props.error ? props.theme.palette.error : 'transparent')};
  outline-offset: -2px;

  &:disabled {
    border: 1px solid ${props => props.theme.palette.grey[600]};
    background-color: ${props => props.theme.palette.grey[300]};
  }

  &:focus {
    outline: 2px solid ${props => props.theme.palette.focus};
    border-bottom: 2px solid ${props => props.theme.palette.grey[600]};
  }

  ::placeholder {
    opacity: 0;
  }

  :focus::placeholder {
    opacity: 0.4;
  }
`

const StyledLabel = styled.label<any>`
  cursor: text;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  transform: translate(${props => props.theme.spacing(2)}, 17px);
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, font-size 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: ${props => props.theme.palette.grey[700]};
`

const StyledHelperContainer = styled.div<any>`
  display: block;
  margin-top: ${props => props.theme.spacing(0.5)};
  padding-left: ${props => props.theme.spacing(2)};
  padding-right: ${props => props.theme.spacing(2)};
  font-size: 1rem;
  line-height: 1rem;
  min-height: 1rem;
`

const StyledError = styled.span<any>`
  color: ${props => props.theme.palette.error};
`

const TextInput: React.FunctionComponent<TextInputProps> = props => {
  const { error, id, onChange, onBlur, placeholder, value, label, name, fullWidth, disabled = false } = props
  const { t } = useTranslation()
  const isDirty = value.length > 0

  return (
    <TextInputRoot isDirty={isDirty}>
      <StyledInput
        error={error}
        fullWidth={fullWidth}
        id={id}
        type="text"
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
      />
      <StyledLabel htmlFor={id}>{label}</StyledLabel>
      <StyledHelperContainer>{error ? <StyledError>{t(error)}</StyledError> : null}</StyledHelperContainer>
    </TextInputRoot>
  )
}

export default TextInput
export { TextInput }
