import React from 'react'
import styled from 'styled-components'
import PageMeta from '../metadata/page-meta'
import { H1 } from '../../ui-user-mgmt/typography/heading'

const StyledContainer = styled.div`
  margin-top: ${p => p.theme.spacing(4)};
  padding: 0;
  width: 80%;
`

const StyledHeading = styled<any>(H1)`
  margin-bottom: ${p => p.theme.spacing(1.5)};
`

export const Content: React.FC<{ title: string; subTitle?: string }> = ({ children, subTitle, title }) => (
  <StyledContainer>
    <PageMeta title={title} subTitle={subTitle} />
    <StyledHeading>{title}</StyledHeading>
    {children}
  </StyledContainer>
)
