import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { ButtonBase } from '../button/button'
import Dropdown from '../dropdown/dropdown'
import { ReactComponent as FintrafficLogoHorizontal } from '../../images/Fintraffic_logo_horizontal.svg'
import { useTranslation } from 'react-i18next'
import { Locale } from 'traficom-registry-shared'
import { Comp } from '../../utils/component'

export type HeaderLink = {
  title: string
  href: string
}

export type Links = Record<Locale.UserLanguage, HeaderLink>[]

export type FintrafficHeaderProps = {
  links: Links
}

const HoverIndicator = css`
  content: '';
  display: block;
  position: absolute;
  background-color: #fff;
  width: 0.5rem;
  height: 0.5rem;
  transform: rotate(45deg);
  bottom: -5px;
  left: calc(50% - 0.25rem);
`

const LogoContainer = styled.a<any>`
  display: flex;
  position: relative;
  width: 6.625em;
  height: 100%;

  & > svg {
    width: 6.625em;
    height: 100%;
  }

  &:hover::before {
    ${HoverIndicator}
  }
`

const LinksContainer = styled.ul<any>`
  display: none;

  ${props => props.theme.mediaquery('md')} {
    display: block;
    list-style-type: none;
    padding-left: ${props => props.theme.spacing(1.5)};
    margin: 0;

    & > li {
      display: inline-block;
    }
  }
`

const LinkContainer = styled.li<any>`
  padding: ${props => props.theme.spacing(1)};
  position: relative;

  * {
    color: ${props => props.theme.palette.text.light};
    font-weight: 700;
    text-decoration: none;
    transition: color 150ms ease-out;

    &:hover {
      color: ${props => props.theme.palette.grey[500]};
    }
  }

  &:hover::before {
    ${HoverIndicator}
  }

  ${props =>
    props.active &&
    css`
      &::before {
        ${HoverIndicator}
      }
    `}
`

const DDLinkContainer = styled.div<any>`
  padding: ${props => props.theme.spacing(1)};

  a {
    color: ${props => props.theme.palette.text.light};
    font-weight: 700;
    text-decoration: none;
    white-space: nowrap;

    &:hover {
      color: ${props => props.theme.palette.grey[500]};
    }
  }
`

const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  width: 100%;

  ${p => p.theme.mediaquery('md')} {
    justify-content: flex-start;
  }
`

const StyledDropdown = styled(Dropdown)`
  display: inline-block;

  ${p => p.theme.mediaquery('md')} {
    display: none;
  }
`

const StyledButtonBase = styled(ButtonBase)`
  height: 100%;
  padding: ${p => p.theme.spacing(1)};
  white-space: nowrap;
  color: ${props => props.theme.palette.white};
`

const LanguageToggle: React.FC = ({ children, ...props }) => <StyledButtonBase {...props}>{children}</StyledButtonBase>

const getLogoLink = (language: Locale.UserLanguage): string => {
  const links = {
    fi: 'https://www.fintraffic.fi/fi',
    sv: 'https://www.fintraffic.fi/sv',
    en: 'https://www.fintraffic.fi/en',
  }
  return links[language]
}

const isExternal = (link: HeaderLink): boolean => link.href.startsWith('https://')

const FintrafficHeader: React.FunctionComponent<FintrafficHeaderProps> = ({ links }) => {
  const { t, i18n } = useTranslation()
  const currentLanguage = i18n.language as Locale.UserLanguage
  const languageLinks = links.map(l => l[currentLanguage])

  return (
    <ActionContainer>
      <LogoContainer target="_blank" rel="noreferrer" href={getLogoLink(currentLanguage)} aria-label={'Fintraffic'}>
        <FintrafficLogoHorizontal role="presentation" />
      </LogoContainer>
      <LinksContainer>
        {languageLinks.map(link => {
          return (
            <LinkContainer key={link.title} active={!isExternal(link)}>
              <ServiceLink link={link} />
            </LinkContainer>
          )
        })}
      </LinksContainer>

      <StyledDropdown
        id="service-selector"
        toggleText={t('services')}
        toggleAs={LanguageToggle}
        label={t('services')}
        dark
        simple
        chevron
      >
        {languageLinks.map(link => (
          <DDLinkContainer key={link.title}>
            <ServiceLink link={link} />
          </DDLinkContainer>
        ))}
      </StyledDropdown>
    </ActionContainer>
  )
}

const ServiceLink: Comp<{ link: HeaderLink }> = ({ link }) =>
  isExternal(link) ? (
    <a target="_blank" rel="noreferrer" href={link.href}>
      {link.title}
    </a>
  ) : (
    <Link to={link.href}>{link.title}</Link>
  )

export default FintrafficHeader
