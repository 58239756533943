import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

export type TextInputProps = {
  id: string
  label?: string
  name: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
  value: string
  error?: string
  fullWidth?: boolean
  disabled?: boolean
}

const TextInputRoot = styled.div<any>`
  margin-top: ${props => props.theme.spacing(1)};
  margin-bottom: ${props => props.theme.spacing(0.2)};

  > input:focus + label {
    transform: translate(${props => props.theme.spacing(2)}, 7px);
    font-size: 13px;
  }

  ${({ isDirty }) => isDirty && labelFocusMixin}
`

const labelFocusMixin = css`
  > label {
    transform: translate(${props => props.theme.spacing(2)}, 7px);
    font-size: 16px;
  }
`

const StyledInput = styled.input<any>`
  font-size: 1rem;
  width: 100%;
  height: 3.5rem;
  padding: 0 ${props => props.theme.spacing(2)} 0;

  border: 1px solid #9696aa;
  border-radius: 4px;

  &:disabled {
    border: 1px solid ${props => props.theme.palette.grey[600]};
    background-color: ${props => props.theme.palette.grey[300]};
  }

  &:focus {
    outline: 2px solid ${props => props.theme.palette.focus};
    border-bottom: 2px solid ${props => props.theme.palette.grey[600]};
  }

  ::placeholder {
    opacity: 0;
  }

  :focus::placeholder {
    opacity: 0.4;
  }
`

const StyledLabel = styled.label<any>`
  cursor: text;
  color: black;
  padding-bottom: 8px;
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
`

const StyledHelperContainer = styled.div<any>`
  display: block;
  margin-top: ${props => props.theme.spacing(0.4)};
  padding-left: ${props => props.theme.spacing(2)};
  padding-right: ${props => props.theme.spacing(2)};
  font-size: 1rem;
  line-height: 1rem;
  min-height: 1rem;
`

const StyledError = styled.span<any>`
  color: ${props => props.theme.palette.error};
`

const TextInput: React.FunctionComponent<TextInputProps> = props => {
  const { error, id, onChange, onBlur, placeholder, value, label, name, fullWidth, disabled = false } = props
  const { t } = useTranslation()
  const isDirty = value.length > 0

  return (
    <TextInputRoot isDirty={isDirty}>
      <StyledLabel htmlFor={id}>{label}</StyledLabel>
      <StyledInput
        error={error}
        fullWidth={fullWidth}
        id={id}
        type="text"
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
      />
      <StyledHelperContainer>{error ? <StyledError>{t(error)}</StyledError> : null}</StyledHelperContainer>
    </TextInputRoot>
  )
}

export default TextInput
export { TextInput }
