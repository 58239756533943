import { Comp } from '../../utils/component'
import styled from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import { api } from '../../services/api'
import { FieldGroup } from '../../ui-user-mgmt/form'

import { Button, DeleteButton } from '../../ui-user-mgmt'
import { CardContent } from './GroupMembers'

export type WorkQueueItem = {
  queueId: string
  timestamp: number
  requestType: string
  userData: { userId: string; mail: string }
}

const ListItem = styled.div<any>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 16px;
  background: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16), 0px 6px 6px rgba(0, 0, 0, 0.23);
`

const JoinRequest: Comp<{ email: string }> = props => {
  const email = props.email
  return <Trans i18nKey="user_management:join_organization_request">{{ email }}</Trans>
}

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
`

const Xicon = styled.span`
    padding 0px 14px;
    font-size: 18px;
    font-weight: 500;
  `

export const WorkQueue: Comp<{ items: WorkQueueItem[]; token: string }> = props => {
  const { t } = useTranslation()
  return (
    <FieldGroup title={t('user_management:work_queue')}>
      {props.items.length > 0 && (
        <>
          {props.items.map(item => (
            <ListItem key={item.timestamp}>
              <CardContent>
                {item.requestType === 'join' ? (
                  <JoinRequest email={item.userData.mail} />
                ) : (
                  `${item.requestType} ${item.userData.mail}`
                )}
                <ButtonGroup>
                  <DeleteButton
                    type="button"
                    variant="secondary"
                    $fullWidth
                    onClick={async () => {
                      const result = await api.rejectJoinRequest(item, { accessToken: props.token })
                      if (result.success) {
                        window.location.reload()
                      } else {
                        console.error(result) // TODO
                      }
                    }}
                  >
                    <Xicon>X</Xicon>
                    {t('user_management:reject_join_request')}
                  </DeleteButton>
                  <Button
                    variant="primary"
                    size="small"
                    onClick={async () => {
                      const result = await api.acceptJoinRequest(item, { accessToken: props.token })
                      if (result.success) {
                        window.location.reload()
                      } else {
                        console.error() // TODO
                      }
                    }}
                  >
                    {t('user_management:accept_join_request')}
                  </Button>
                </ButtonGroup>
              </CardContent>
            </ListItem>
          ))}
        </>
      )}
    </FieldGroup>
  )
}
