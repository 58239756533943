import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Page } from '../layout-user-mgmt'
import { AuthenticatedTemplate, useMsalAuthentication } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'
import { api } from '../../services/api'
import { Comp } from '../../utils/component'
import { Form, FieldGroup, Submit, SubmitButton, TextField } from '../../ui-user-mgmt/form'
import { Validation } from 'traficom-registry-shared'
import { getRedirectRequest } from './MsalInstance'
import styled from 'styled-components'
import { FailureData } from '../../services/apiTypes'
import { Button } from '../../ui-user-mgmt'
import { Link } from 'react-router-dom'
import { JoinOrganizationResponse, Organization } from 'traficom-registry-shared/out/model/UserProfile'
import { RadioSelectField } from '../../ui-common/form'

type MailAddressWithBusinessId = {
  mail: string
  businessId: string
}
type BusinessId = { businessId: string }

const PageContainer = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: center;
  border-top: 1px solid #000;
`

const FormAndTitleContainer = styled.div`
  width: 50%;
  padding-bottom: 48px;
`

const FormContainer = styled.div`
  width: 100%;
`

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-right: auto;
  flex-wrap: wrap;
  gap: 8px;
  & > button,
  a {
    margin-bottom: 5px;
    margin-top: 5px;
  }
`

export const JoinOrganization: Comp = () => {
  const { t } = useTranslation()
  const [showSubmitResult, setShowSubmitResult] = useState<boolean>(false)
  const [submitError, setSubmitError] = useState<FailureData<object> | undefined>(undefined)
  const [availableOrgs, setAvailableOrgs] = useState<Organization[] | undefined>(undefined)
  const [selectedEmail, setSelectedEmail] = useState<string | undefined>(undefined)

  const tokenRequest = getRedirectRequest('/#/join-organization')
  const { acquireToken } = useMsalAuthentication(InteractionType.Redirect, tokenRequest)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const submit: Submit<BusinessId> = async (values, helpers) => {
    const authResult = await acquireToken(InteractionType.Redirect, tokenRequest)
    const accessToken = authResult?.accessToken ?? ''
    const result = await api.joinOrganization({ ...values }, { accessToken })
    if (result.success) {
      const res = result.data as JoinOrganizationResponse

      if (!res.success) {
        console.log(res.availableOrgs)
        setSelectedEmail(values.businessId)
        //setAvailableOrgs(res.availableOrgs)
      } else {
        setSubmitError(undefined)
        setShowSubmitResult(true)
      }
    } else {
      setSubmitError(result.data as any)
      setShowSubmitResult(true)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const submitSelect: Submit<MailAddressWithBusinessId> = async (values, helpers) => {
    console.log('submitSelect')
    console.log('businessId: ' + values.businessId)
    console.log('mail: ' + selectedEmail)

    const authResult = await acquireToken(InteractionType.Redirect, tokenRequest)
    const accessToken = authResult?.accessToken ?? ''
    const result = await api.joinOrganization({ mail: selectedEmail, businessId: values.businessId }, { accessToken })
    if (result.success) {
      const res = result.data as JoinOrganizationResponse

      if (!res.success) {
        console.log(res.availableOrgs)
        setSelectedEmail(values.mail)
        setAvailableOrgs(res.availableOrgs)
      } else {
        setAvailableOrgs(undefined)
        setSubmitError(undefined)
        setShowSubmitResult(true)
      }
    } else {
      setAvailableOrgs(undefined)
      setSubmitError(result.data as any)
      setShowSubmitResult(true)
    }
  }

  return (
    <PageContainer>
      <AuthenticatedTemplate>
        <FormAndTitleContainer>
          <Page.Content title={t('user_management:join_organization')}>
            {!showSubmitResult && !availableOrgs && (
              <FormContainer>
                <Form<BusinessId> initialValues={{ businessId: '' }} onSubmit={submit} validate={businessIdValidator}>
                  <FieldGroup>
                    <p>{t('user_management:join_organization_info')}</p>
                    <TextField label={t('personal_data:company_id')} name="businessId" disabled={false} />
                    <ButtonGroup>
                      <SubmitButton labelKey="user_management:join_organization" />
                    </ButtonGroup>
                  </FieldGroup>
                </Form>
              </FormContainer>
            )}

            {availableOrgs && (
              <FormContainer>
                <Form<MailAddressWithBusinessId>
                  initialValues={{ mail: '', businessId: '' }}
                  onSubmit={submitSelect}
                  validate={emailAndBusinessIdValidator}
                >
                  <p>{t('user_management:select_multiple_organization')}</p>

                  <RadioSelectField
                    name="businessId"
                    options={availableOrgs.map(o => ({ value: o.businessId, label: o.name }))}
                  />

                  <ButtonGroup>
                    <SubmitButton labelKey="user_management:join_organization" />
                  </ButtonGroup>
                </Form>
              </FormContainer>
            )}

            <ButtonContainer>
              {showSubmitResult && submitError !== undefined && (
                <div>
                  <p>{t('user_management:join_error')}</p>
                  <p>{submitError?.message ? t(submitError?.message) : ''}</p>
                  <Button as={Link} to="organizations">
                    Jatka
                  </Button>
                </div>
              )}
              {showSubmitResult && submitError == undefined && (
                <div>
                  <p>{t('user_management:join_request_sent')}</p>
                  <Button as={Link} to="organizations">
                    Jatka
                  </Button>
                </div>
              )}
            </ButtonContainer>
          </Page.Content>
        </FormAndTitleContainer>
      </AuthenticatedTemplate>
    </PageContainer>
  )
}

const businessId: Validation.Builder<BusinessId> = () => ({
  businessId: Validation.builders.nonEmpty,
})

const businessIdValidator = Validation.create(businessId)

const mailAndBusinessId: Validation.Builder<MailAddressWithBusinessId> = () => ({
  //mail: Validation.builders.email,
  //businessId: Validation.builders.nonEmpty
})

const emailAndBusinessIdValidator = Validation.create(mailAndBusinessId)
