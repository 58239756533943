import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import useClickOutside from '../../hooks/use-click-outside'
import { ButtonBase } from '../button/button'
import { ReactComponent as Chevron } from '../../images/chevron.svg'

const DropdownContainer = styled.nav<any>`
  position: relative;
  ${p => p.fullWidth && 'width: 100%'}
`

const List = styled.ul<any>`
  position: absolute;
  background-color: ${p => (p.dark ? p.theme.palette.black : p.theme.palette.white)};
  flex: 1 0 100%;
  top: ${p => (p.simple ? '100%' : css`calc(100% + ${p => p.theme.spacing(0.4)})`)};
  min-width: 100%;
  z-index: 100;
  right: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  border: ${p => p.theme.borders['s']} solid ${p => (p.dark ? p.theme.palette.black : p.theme.palette.grey[500])};
  border-radius: ${p => (p.simple ? 'none' : '3px')};
`

const ListItem = styled.li<any>`
  &:not(:first-child) {
    border-top: ${p => p.theme.borders['s']} solid ${p => p.theme.palette.grey[700]};
  }

  & > * {
    width: calc(100% - ${p => p.theme.spacing(0.4)});
    display: block;
    padding: ${p => p.theme.spacing(1)} ${p => p.theme.spacing(1.5)};
  }
`

export type DropdownProps = {
  toggleAs?: React.ElementType | keyof JSX.IntrinsicElements
  id: string
  dark?: boolean
  fullWidth?: boolean
  toggleText: string
  label?: string
  chevron?: boolean
  className?: string
  simple?: boolean
  outlineColor?: string
}

const StyledChevron = styled(Chevron)`
  width: 22px;
  height: 22px;
`

const StyledBaseButton = styled(ButtonBase)<{ isOpen: boolean; outlineColor?: string }>`
  display: inline-flex;
  flex: 0 0 auto;

  &:active,
  &:focus {
    outline-color: ${p => p.outlineColor ?? 'auto'};
  }

  & > ${StyledChevron} {
    ${p => p.isOpen && 'transform: rotate(180deg)'};
  }
`

const Dropdown: React.FC<DropdownProps> = ({
  children,
  toggleAs,
  id,
  dark,
  fullWidth,
  toggleText,
  label,
  chevron,
  className,
  simple,
  outlineColor,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const ref = useClickOutside<HTMLElement>(() => setIsOpen(false))

  return (
    <DropdownContainer fullWidth={fullWidth} ref={ref} className={className}>
      <StyledBaseButton
        outlineColor={outlineColor}
        as={toggleAs}
        onClick={() => setIsOpen(!isOpen)}
        aria-controls={id}
        aria-expanded={isOpen}
        aria-haspopup="true"
        aria-label={label}
        isOpen={isOpen}
        type="button"
      >
        {toggleText}
        {chevron && <StyledChevron />}
      </StyledBaseButton>
      {isOpen && (
        <List role="menu" id={id} dark={dark} simple={simple}>
          {React.Children.map(children, child => (
            <ListItem onClick={() => setIsOpen(false)} role="menuitem">
              {child}
            </ListItem>
          ))}
        </List>
      )}
    </DropdownContainer>
  )
}

export default Dropdown
export { Dropdown }
