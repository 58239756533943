import { InteractionType } from '@azure/msal-browser'
import { TokenClaims } from '@azure/msal-common'
import { useMsalAuthentication } from '@azure/msal-react'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { api } from '../../services/api'
import { actions, RegistrationContext } from '../../state/registration'
import { Button, DeleteButton } from '../../ui-user-mgmt'
import LoadingSpinner from '../../ui-user-mgmt/loading/loading-spinner'
import { Comp } from '../../utils/component'
import { ErrorState } from '../error/error-page'
import { Page } from '../layout-user-mgmt'
import { getRedirectRequest } from './MsalInstance'

/* eslint-disable */

type AdGroup = {
  id: number
  groupDisplayName: string
  groupADName: string
  accessStartDate: string
  accessEndDate: string
}

type Org = {
  orgId?: string
  orgName?: string
  new?: boolean
  groups?: AdGroup[] | undefined
}

const ButtonContainer = styled.div`
  display: flex;
  margin-right: auto;
  flex-wrap: wrap;
  gap: 8px;

  & > button,
  a {
    margin-bottom: 5px;
    margin-top: 5px;
  }
`

export const AdminAdGroups: Comp = () => {
  const { t } = useTranslation()
  const [roles, setRoles] = useState<string[]>([])
  const [idTokenClaims, setIdTokenClaims] = useState<TokenClaims>()
  const [accessToken, setAccessToken] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const tokenRequest = getRedirectRequest('/#/admin/adgroups')
  const { result, error } = useMsalAuthentication(InteractionType.Redirect, tokenRequest)

  const [orgGroups, setOrgGroups] = useState<Org[]>([])
  const [editedOrg, setEditedOrg] = useState<Org | undefined>(undefined)
  const [editedGroup, setEditedGroup] = useState<AdGroup | undefined>(undefined)
  const [isSaving, setIsSaving] = useState<boolean>(false)

  const { dispatch } = useContext(RegistrationContext)

  const fetchData = async () => {
    if (result) {
      const claims = result.idTokenClaims as TokenClaims
      setIdTokenClaims(claims)
      setRoles(claims.roles ?? [])
      setAccessToken(result.accessToken)

      const orgs = (await api.getOrgGroupPermissions(undefined, { accessToken: result.accessToken })).data as Org[]

      const allOrganizations = await (api as any).getAllOrganizations(undefined, { accessToken: result.accessToken })
      if (allOrganizations.success) {
        orgs.map(o => {
          o.orgName = allOrganizations.data.find((org: unknown) => (org as any).businessId === o.orgId)?.orgName
        })
      }

      setOrgGroups(
        orgs.map(o => ({ ...o, new: false, groups: !o.groups ? [] : o.groups?.map((g, ix) => ({ ...g, id: ix })) })),
      )

      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData().catch(console.error)
  }, [error, result])

  const addOrg = () => {
    const newOrg = {
      new: true,
      orgId: '',
      groups: [],
    }

    setEditedOrg(newOrg)
  }

  const validateOrg = (org: Org) => {
    return (
      org.orgId &&
      org.orgId.length > 0 &&
      org.groups?.every(
        g =>
          g.accessEndDate &&
          g.accessEndDate.length > 0 &&
          g.accessStartDate &&
          g.accessStartDate.length > 0 &&
          g.groupADName &&
          g.groupADName.length > 0 &&
          g.groupDisplayName &&
          g.groupDisplayName.length > 0,
      )
    )
  }

  const editOrg = (businessId: string | undefined) => {
    setOrgGroups(orgGroups.map(org => ({ ...org, beingEdited: org.orgId === businessId })))
    setEditedOrg(orgGroups.find(org => org.orgId == businessId))
  }

  const saveOrg = async (newOrg: Org) => {
    setIsSaving(true)
    const result = await api.saveOrgGroupPermission(newOrg, { accessToken: accessToken })
    setIsSaving(false)

    if (!result.success) {
      dispatch(actions.addSnackbarMessage(t((result.data as any).message!), 'ERROR'))
      return
    }

    if (newOrg.new) {
      newOrg.new = false
      setOrgGroups([...orgGroups, newOrg])
    } else {
      setOrgGroups(orgGroups.map(org => (org.orgId == newOrg.orgId ? newOrg : org)))
    }

    setEditedOrg(undefined)
    fetchData().catch(console.error)
  }

  const removeOrg = async (businessId: string | undefined) => {
    setIsSaving(true)
    const result = await api.deleteOrgGroupPermission({ businessId: businessId }, { accessToken: accessToken })
    setIsSaving(false)
    setOrgGroups(orgGroups.filter(org => org.orgId != businessId))
    if (editedOrg && editedOrg.orgId == businessId) {
      setEditedOrg(undefined)
    }
  }

  const newGroup = () => {
    if (!editedOrg) {
      return
    }

    const today = new Date()
    const oneYearAhead = new Date()
    oneYearAhead.setDate(oneYearAhead.getDate() + 365)

    setEditedOrg({
      ...editedOrg,
      groups: [
        ...editedOrg.groups!,
        {
          groupADName: '',
          groupDisplayName: '',
          accessStartDate: today.toLocaleDateString('fr-CA'),
          accessEndDate: oneYearAhead.toLocaleDateString('fr-CA'),
          id: editedOrg!.groups!.length,
        },
      ],
    })
  }

  const changeOrg = (value: string) => {
    if (editedOrg) {
      setEditedOrg({ ...editedOrg, orgId: value })
    }
  }

  const cancelEdit = () => {
    setEditedOrg(undefined)
  }

  const removeGroup = (group: AdGroup) => {
    setEditedOrg({ ...editedOrg, groups: editedOrg?.groups?.filter(g => g.id != group.id) })
  }

  const updateGroup = (group: AdGroup) => {
    setEditedOrg({ ...editedOrg, groups: editedOrg?.groups?.map(g => (g.id == group.id ? group : g)) })
  }

  if (error) {
    return <ErrorState error="generic" />
  }

  return (
    <PageContainer>
      <FormAndTitleContainer>
        <Page.Content title={t('user_management:ans_admin')}>
          <h1>{t('user_management:ad_group_settings')}</h1>

          {editedOrg && (
            <LoadingSpinner isLoading={isSaving}>
              {editedOrg.new === true && <h2>{t('user_management:new_org_ad_settings')}</h2>}
              {editedOrg.new === false && <h2>{t('user_management:edit_org_ad_settings')}</h2>}

              {editedOrg.new && (
                <div>
                  <label>{t('personal_data:company_id')}</label>
                  <input value={editedOrg.orgId} onChange={e => changeOrg(e.target.value)}></input>
                </div>
              )}

              {!editedOrg.new && (
                <div>
                  {t('personal_data:company_id')}: {editedOrg.orgId}
                </div>
              )}

              {
                <div>
                  <h2>{t('user_management:ad_groups_for_org')}</h2>

                  <Button variant="primary" size="small" onClick={() => newGroup()}>
                    {t('user_management:add_ad_group_setting')}
                  </Button>

                  {editedOrg.groups!.length > 0 && (
                    <Table>
                      <thead>
                        <tr>
                          <Th>{t('user_management:ad_group_display_name')}</Th>
                          <Th>{t('user_management:ad_group_name')}</Th>
                          <Th>{t('user_management:ad_group_active_start_date')}</Th>
                          <Th>{t('user_management:ad_group_active_end_date')}</Th>
                          <Th>{t('user_management:organization_actions')}</Th>
                        </tr>
                      </thead>
                      <tbody>
                        {editedOrg.groups?.map(g => (
                          <tr key={editedOrg.orgId! + g.id}>
                            <Td>
                              <input
                                required
                                value={g.groupDisplayName}
                                onChange={e => updateGroup({ ...g, groupDisplayName: e.target.value })}
                              ></input>
                            </Td>
                            <Td>
                              <input
                                value={g.groupADName}
                                onChange={e => updateGroup({ ...g, groupADName: e.target.value })}
                              ></input>
                            </Td>
                            <Td>
                              <input
                                value={g.accessStartDate}
                                onChange={e => updateGroup({ ...g, accessStartDate: e.target.value })}
                              ></input>
                            </Td>
                            <Td>
                              <input
                                value={g.accessEndDate}
                                onChange={e => updateGroup({ ...g, accessEndDate: e.target.value })}
                              ></input>
                            </Td>
                            <Td>
                              <ButtonContainer>
                                <DeleteButton variant="primary" size="small" onClick={() => removeGroup(g)}>
                                  {t('user_management:remove_ad_group')}
                                </DeleteButton>
                              </ButtonContainer>
                            </Td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}

                  {editedOrg.groups!.length == 0 && <p>{t('user_management:no_ad_group_settings')}</p>}

                  {editedGroup && (
                    <div>
                      <input value={editedGroup.groupDisplayName}></input>
                    </div>
                  )}
                </div>
              }

              <ButtonContainer>
                {
                  <Button
                    disabled={!validateOrg(editedOrg)}
                    variant="primary"
                    size="small"
                    onClick={() => saveOrg(editedOrg)}
                  >
                    {t('user_management:update_profile')}
                  </Button>
                }

                <Button variant="primary" size="small" onClick={() => cancelEdit()}>
                  {t('common:cancel')}
                </Button>

                {!editedOrg.new && (
                  <DeleteButton variant="primary" size="small" onClick={() => removeOrg(editedOrg.orgId)}>
                    {t('common:delete')}
                  </DeleteButton>
                )}
              </ButtonContainer>
            </LoadingSpinner>
          )}

          {!editedOrg && (
            <LoadingSpinner isLoading={isLoading}>
              {!isLoading && (
                <div>
                  <ButtonContainer>
                    <Button variant="primary" size="small" onClick={() => addOrg()}>
                      {t('user_management:add_org_ad_settings')}
                    </Button>
                  </ButtonContainer>

                  {orgGroups.length > 0 && (
                    <Table>
                      <thead>
                        <tr>
                          <Th>{t('personal_data:company_id')}</Th>
                          <Th>{t('personal_data:name')}</Th>
                          <Th>{t('user_management:num_ad_groups')}</Th>
                          <Th>{t('user_management:organization_actions')}</Th>
                        </tr>
                      </thead>
                      <tbody>
                        {orgGroups.map(org => (
                          <tr key={org.orgId}>
                            {<Td align="left">{org.orgId}</Td>}

                            <Td>{org.orgName}</Td>

                            <Td align="right">{org.groups?.length}</Td>

                            <Td>
                              <ButtonContainer>
                                <Button variant="primary" size="small" onClick={() => editOrg(org.orgId)}>
                                  {t('user_management:edit_organization')}
                                </Button>
                              </ButtonContainer>
                            </Td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}

                  {orgGroups.length == 0 && <p>{t('user_management:no_org_ad_settings')}</p>}
                </div>
              )}
            </LoadingSpinner>
          )}
        </Page.Content>
      </FormAndTitleContainer>
    </PageContainer>
  )
}

const PageContainer = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: center;
  border-top: 1px solid #000;
`

const FormAndTitleContainer = styled.div`
  width: 50%;
  padding-bottom: 48px;
`

const Table = styled.table`
  border: 1px solid;
  width: 100%;
`

const Th = styled.th`
  border: 1px solid;
`

const Td = styled.td<any>`
  border: 1px solid;
  padding: 5px;
`
