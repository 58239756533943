import { InteractionType } from '@azure/msal-browser'
import { TokenClaims } from '@azure/msal-common'
import { useMsalAuthentication } from '@azure/msal-react'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { validators } from 'traficom-registry-shared'
import { Organization } from 'traficom-registry-shared/out/model/UserProfile'
import { api } from '../../services/api'
import { Button } from '../../ui-user-mgmt'
import { Form, RadioGroupBooleanField, Submit, SubmitButton, TextField, useFormErrorHandler } from '../../ui-user-mgmt/form'
import Loader from '../../ui-user-mgmt/loader/loader'
import { Comp } from '../../utils/component'
import { FormValues } from '../../utils/types'
import { ErrorState } from '../error/error-page'
import { Page } from '../layout-user-mgmt'
import { GroupMembers } from './GroupMembers'
import { getRedirectRequest } from './MsalInstance'

/* eslint-disable */

export const AdminOrganizations: Comp = () => {
  const { t } = useTranslation()
  const [roles, setRoles] = useState<string[]>([])
  const [idTokenClaims, setIdTokenClaims] = useState<TokenClaims>()
  const [accessToken, setAccessToken] = useState<string>('')
  const [organizations, setOrganizations] = useState<Organization[]>([])
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)

  const tokenRequest = getRedirectRequest('/#/admin/organizations')
  const { result, error } = useMsalAuthentication(InteractionType.Redirect, tokenRequest)

  useEffect(() => {
    const fetchData = async () => {
      if (result) {
        const claims = result.idTokenClaims as TokenClaims
        setIdTokenClaims(claims)
        setRoles(claims.roles ?? [])
        setAccessToken(result.accessToken)
        setLoadingComplete(true)
      }
    }

    fetchData().catch(console.error)
  }, [error, result])

  if (error) {
    return <ErrorState error="generic" />
  }

  return (
    <PageContainer>
      <FormAndTitleContainer>
        <Page.Content title={t('user_management:ans_admin')}>
          {!loadingComplete && <Loader text={t('common:loading')} />}
          {loadingComplete && roles.some(r => r === 'ans_admin') ? (
            <>
              <Button variant="primary" $fullWidth as={Link} to="create-org-onbehalf">
                {t('user_management:create_organization')}
              </Button>

              <h1>{t('user_management:organizations')}</h1>
              <Organizations orgs={organizations} token={accessToken} setOrganizations={setOrganizations} />
            </>
          ) : (
            loadingComplete && <p>{t('user_management:no_access')}</p>
          )}
        </Page.Content>
      </FormAndTitleContainer>
    </PageContainer>
  )
}

const List = styled.ul`
  list-style: none;
  padding: 0px 20px;
  background-color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: 3px solid ${'#9b8dab'};
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.16);
`

const ListItem = styled.li`
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  border-top: 1px solid ${'#e0dddd'};

  :first-of-type {
    border-top: none;
  }
`

const ErrorMessage = styled.div`
  color: red;
`

const PageContainer = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: center;
  border-top: 1px solid #000;
`

const FormAndTitleContainer = styled.div`
  width: 50%;
  padding-bottom: 48px;
`

const Organizations: Comp<{ orgs: Organization[]; token: string; setOrganizations: Function }> = props => {
  const { t } = useTranslation()
  const [editOrg, setEditOrg] = useState('')
  const [noSearchResults, setNoSearchResults] = useState(false)
  const tokenRequest = getRedirectRequest('/#/org-admin')
  const { result, error } = useMsalAuthentication(InteractionType.Redirect, tokenRequest)
  const claims = result?.idTokenClaims as TokenClaims

  const organizationSearchDefaults = (): FormValues<{ organization: string }> => ({
    organization: '',
  })

  const submit: Submit<{ organization: string }> = async (values, helpers) => {
    const result = await api.getOrganizationsByNameOrBusinessId(values, { accessToken: props.token })
    if (result.success) {
      if (result.data.length > 0) {
        props.setOrganizations(result.data)
        setNoSearchResults(false)
      } else {
        props.setOrganizations([])
        setNoSearchResults(true)
      }
    } else {
      console.error(result)
    }
    helpers.setSubmitting(false)
    helpers.resetForm()
  }

  return (
    <>
      <Form<{ organization: string }>
        initialValues={organizationSearchDefaults()}
        onSubmit={submit}
        validate={validators.getOrganizationsByNameOrBusinessId}
      >
        <TextField label={t('user_management:organization_search_label')} name="organization" disabled={false} />
        <SubmitButton labelKey="common:search" />
      </Form>
      {noSearchResults ? <ErrorMessage>{t('user_management:search_failed')}</ErrorMessage> : ''}
      {props.orgs.length > 0 && (
        <List>
          {props.orgs.map(org => (
            <OrganizationComponent
              key={org.businessId}
              org={org}
              editOrg={editOrg}
              setEditOrg={setEditOrg}
              token={props.token}
              adminOid={claims}
            ></OrganizationComponent>
          ))}
        </List>
      )}
    </>
  )
}

const OrganizationComponent = ({
  org,
  editOrg,
  setEditOrg,
  token,
  adminOid,
}: {
  org: Organization
  editOrg: string
  setEditOrg: Dispatch<SetStateAction<string>>
  token: string
  adminOid: TokenClaims
}) => {
  const { t } = useTranslation()

  return (
    <ListItem>
      <p>{org.name}</p>
      <p>{org.businessId}</p>
      <p>{org.contactName}</p>
      <Button variant="primary" size="small" onClick={() => setEditOrg(editOrg === org.businessId ? '' : org.businessId)}>
        {t('user_management:toggle_edit')}
      </Button>
      {editOrg === org.businessId && <OrganizationForm org={org} token={token} adminOid={adminOid} />}
    </ListItem>
  )
}

const organizationDefaults = (props: Organization): FormValues<Organization> => ({
  name: props.name,
  businessId: props.businessId,
  contactName: props.contactName,
  address: props.address ?? '',
  postalCode: props.postalCode ?? '',
  municipality: props.municipality ?? '',
  phoneNumber: props.phoneNumber,
  contactPhoneNumber: props.contactPhoneNumber,
  astraGovernmentOrganization: props.astraGovernmentOrganization,
})

const OrganizationForm: Comp<{ org: Organization; token: string; adminOid: TokenClaims }> = props => {
  const { t } = useTranslation()
  const handleError = useFormErrorHandler<Organization>()
  const [orgAdminIds, setOrgAdminIds] = useState<undefined | string[]>(undefined)

  useEffect(() => {
    const fetchData = async () => {
      const adminIds = await api.getOrgAdmins({ businessId: props.org.businessId }, { accessToken: props.token })
      if (adminIds.success) {
        setOrgAdminIds(adminIds.data)
      }
    }
    fetchData().catch(console.error)
  }, [props.org.businessId])

  const submit: Submit<Organization> = async (values, helpers) => {
    const result = await api.updateOrganization({ ...values }, { accessToken: props.token })
    if (result.success) {
      window.location.reload()
    } else {
      handleError(result.data as any, helpers)
    }
  }

  if (props.org === undefined) {
    return <></>
  }

  return (
    <Form<Organization>
      initialValues={organizationDefaults(props.org)}
      onSubmit={submit}
      validate={validators.createOrganization}
    >
      <TextField label={t('personal_data:name')} name="name" disabled={false} />
      <TextField label={t('personal_data:company_id')} name="businessId" disabled={true} />
      <TextField label="Yhteyshenkilön nimi" name="contactName" disabled={false} />
      <TextField label={t('personal_data:address')} name="address" disabled={false} />
      <TextField label={t('personal_data:postal_code')} name="postalCode" disabled={false} />
      <TextField label={t('personal_data:municipality')} name="municipality" disabled={false} />
      <TextField label={t('personal_data:telephone')} name="phoneNumber" disabled={false} />
      <TextField label="Yhteyshenkilön puhelinnumero" name="contactPhoneNumber" disabled={false} />
      <RadioGroupBooleanField
        label={t('user_management:astra_gov_org')}
        name={'astraGovernmentOrganization'}
        options={[
          { value: 'true', label: t('common:yes') },
          { value: 'false', label: t('common:no') },
        ]}
      />
      <SubmitButton labelKey="user_management:update_organization" />
      {orgAdminIds !== undefined && (
        <GroupMembers
          businessId={props.org.businessId}
          orgAdminIds={orgAdminIds}
          setOrgAdminIds={setOrgAdminIds}
          token={props.token}
          adminOid={props.adminOid.oid!}
        />
      )}
    </Form>
  )
}
